<template>
  <div class="pbf">
     
     <BrandTabReviews
      :inurl="'/strains/' + props.id + '/reviews'"
      :strain-id="props.id" 
      :sort-options="[
        {id:'rate', name: $t('universal_sort_review')},    
        {id:'create', name: $t('universal_sort_creation_date')},
        {id:'az', name: $t('universal_sort_az')},
        {id:'gwatt', name: $t('universal_sort_gwatt')},
        {id:'gplant', name: $t('universal_sort_gplant')},
      ]"   
      />

  </div>
</template>

<script setup>
 
const {$api, $tagsUtil, $ga} = useNuxtApp()

const props = defineProps({
  id: {
    type: Number
  }
})

</script>


<style scoped>
 

</style>
